<template src="./media-manager.html"></template>

<script>
import Http from '@/shared/http-config'
import moment from 'moment'
import functions from '@/views/marketing/media-manager/functions'
import {isMobile} from '@/shared/utils.js'
import introJs from '@/shared/intro.js';

export default {
  name: "MediaManager",
  mixins: [functions],
  data: function () {
    return {
      user: {},
      meta: {},
      introJs: introJs,
      moment: moment,
      filter: {
        category_id: 0,
        approved: false
      },
      calendar: {
        date: moment(),
        month: moment().format('MMMM YYYY'),
        weeks: []
      },
      posts: [],
      categories: [],
      activePreviewPost: {id: 0, category: {}},
      fields: [
        {
          key: 'date',
          label: 'Datum',
          formatter: value => {
            if(isMobile()){
              return this.$options.filters.formatDate(value, 'DD.MM. YYYY')
            }
            return this.$options.filters.formatDate(value, 'DD.MM.YYYY')
          }
        },
        { key: 'category.title', label: 'Kategorie'},
        {
          key: 'text',
          label: 'Post',
          formatter: (value) => {
            return value.substring(0, 25) + '...';
          }
        },
        { key: 'type', label: 'Kanal', tdClass: 'd-none d-sm-table-cell', thClass: 'd-none d-sm-table-cell'},
        { key: 'actions', label: '', tdClass: 'text-right'},
      ],
      colorsToCat: {
        2: '#ee7326',
        3: '#783f91',
        4: '#51aee2',
        5: '#374c92',
        9: '#619b30'
      },
      typeTitle: {
        fb: 'Facebook',
        insta: 'Instagram'
      },
      oldIndex: false
    }
  },
  computed: {
    listView () {
      return this.$store.getters.get('listView');
    }
  },
  watch: {
    filter: {
      handler: function () {
        this.refreshCurrentView();
      },
      deep: true
    },
    listView: {
      handler: function (val) {
        if(!val){
          this.loadCalendar();
        }
      },
    },
  },
  mounted() {
    introJs.firstLoad('social-media');
  },
  created() {
    let self = this;

    if(localStorage.calendar_date) {
      self.calendar.date = moment(localStorage.calendar_date);
      self.calendar.month = moment(localStorage.calendar_date).format('MMMM YYYY');
    }

    self.user = self.$store.getters.getUser;
    self.loadCalendar();

    Http.request('GET', '/social/categories').then(function (categories) {
      self.categories = categories.data;
    });

    introJs.setOption('steps', [
      {
        element: '#facebook h1',
        intro: "Dein Redaktionsplan zeigt dir deine geposteten, geplanten, freigegebenen sowie nicht freigegebenen Posts für Facebook bzw. Instagram.",
      },
      {
        element: '#facebook #social-calendar',
        intro: "In der Kalenderansicht sind deine Posts auf die jeweiligen Tage verteilt. Über die Pfeile neben der Monatsangabe kannst du durch die Monate blättern.",
      },
      {
        element: '#facebook #social-calendar .calendar-day:first-child',
        intro: "Die Posts sind in unterschiedliche Kategorien eingeordnet, die du an der Farbe sowie Überschrift erkennst. Neue GECCO Posts im Redaktionsplan sind in Petrol und mit einem \"!\" markiert.",
      },
      {
        element: '#facebook #social-calendar .calendar-day.active',
        intro: "Wenn du mit der Maus über einen Tag im Kalender wanderst, taucht ein kleines Plus (+) auf. Mit diesem oder dem Button rechts unten, kannst du neue Posts erstellen. Durch Klick auf einen Post siehst du eine Vorschau deines Beitrags.",
      },
      {
        element: '#facebook .approve-posts',
        intro: "Wenn du zuvor den Filter auf 'Nicht freigegebene Posts' gesetzt hast, kannst du über diese Buttons deine Posts einzeln oder für die gesamte Ansicht freigeben.",
        disableInteraction: true
      },
      {
        element: '#facebook .filter',
        intro: "Du kannst deine Posts über den linken Button filtern. In der Mitte kannst du in die Listenansicht wechseln und rechts Einstellungen zu deinem Redaktionsplan vornehmen. Wechsle nun durch Klicken auf den mittleren Button in die Listenansicht.",
      },
      {
        element: '#facebook h1',
        intro: "In der Listenansicht des Redaktionsplans kannst du deine Posts in einer anderen Ansicht anschauen  als du sie aus der Kalenderansicht kennst.",
      },
      {
        element: '#facebook #postsTable',
        intro: "In der Tabelle siehst du für welche Kanäle der Post angelegt ist, kannst diese kopieren oder löschen.",
      },
      {
        element: '#facebook .filter',
        intro: "Die anderen Funktionen sind identisch mit denen der Kalenderansicht. Zu dieser kannst du über den mittleren Button übrigens zurückwechseln.",
      },
      {
        element: '#facebook #postsTable tbody tr',
        intro: "Öffne jetzt einen Post.",
      },
      {
        intro: "In der Post Vorschau siehst du sowohl deine ausgewählten Kanäle, hast aber auch die Möglichkeit den Post direkt zu bearbeiten oder zu löschen. Mit schließen beendest du die Vorschau.",
      },
    ]);

    introJs.onbeforechange(function(targetElement) {
      if(introJs._currentStep === 2){
        self.$forceUpdate();
      }
      if(introJs._currentStep === 6 && !self.listView){
        return false;
      }
      if(introJs._currentStep === 10){
        if(self.activePreviewPost.id === 0){
          return false;
        }
      }
    });
  },
  methods: {
    loadCalendar(){
      let self = this;
      let a = moment(self.calendar.date).startOf('month').startOf('week');
      let b = moment(self.calendar.date).endOf('month').endOf('week');

      self.calendar.weeks = [];
      return self.getPosts('?from=' + a.format('YYYY-MM-DD') + '&to=' + b.format('YYYY-MM-DD') + '&per_page=9999').then(function (result){
        let days = 0;
        let week = [];
        for (let m = moment(a);m.isBefore(b); m.add(1, 'days')) {
          week.push({
            date: moment(m),
            posts: result.filter(item => {return moment(item.date).format('YYYY-MM-DD') === m.format('YYYY-MM-DD')})
          });
          days++;
          if (days % 7 === 0) {
            self.calendar.weeks.push(week);
            week = [];
          }
        }
      });
    },
    loadPosts(ctx, callback) {
      let self = this;
      let a = moment(self.calendar.date).startOf('month');
      let b = moment(self.calendar.date).endOf('month');
      const params = '?page=' + ctx.currentPage + '&per_page=10&from=' + a.format('YYYY-MM-DD') + '&to=' + b.format('YYYY-MM-DD');
      this.getPosts(params).then(function (result){
        callback(result);
      });
    },
    getPosts(params) {
      let self = this;

      if(self.filter.category_id > 0){
        params += '&category=' + self.filter.category_id;
      }
      if(self.filter.approved === true){
        params += '&not_approved=1';
      }

      return Http.request('GET', '/social/posts' + params).then(function (posts) {
        let result = posts.data.map(post => {
          if(post.meta){
            post.types = post.meta.posts.map(post => {return post.type});
          }
          else if(post.news){
            post.types = post.news.posts.map(post => {return post.type});
          }
          else if(post.course){
            post.types = post.course.posts.map(post => {return post.type});
          }
          else if(post.guestbook){
            post.types = post.guestbook.posts.map(post => {return post.type});
          }
          else {
            post.types = [post.type];
          }
          return post;
        });

        self.meta = posts.meta;
        self.posts = result;
        return result;
      });
    },
    changeMonth(value){
      if(value === 'today'){
        this.calendar.date = moment();
        this.calendar.month = moment().format('MMMM YYYY');
      }
      else{
        this.calendar.date = this.calendar.date.add(value, 'month');
        this.calendar.month = this.calendar.date.format('MMMM YYYY');
      }

      localStorage.calendar_date = this.calendar.date.format('YYYY-MM-DD');
      this.refreshCurrentView();
    },
    postPreview(post){
      let self = this;
      self.activePreviewPost = post;
      self.$bvModal.show('post-preview-modal');
    },
    activatePosts() {
      let self = this;
      let requests = [];
      let oldIndex = self.posts.findIndex(self.currentPreviewIndex);
      self.getLinkedPosts(self.activePreviewPost).forEach(function (post){
        post.active = true;
        post.meta = {id: self.activePreviewPost.meta.id};
        post.link = self.$options.filters.userUrl(post.link);
        requests.push(
          Http.request('POST', '/social/posts/' + post.id, post)
        );
      });

      Promise.all(requests).then(function () {
        self.refreshCurrentView(oldIndex);
      });
    },
    releasePosts() {
      let self = this;
      let requests = [];
      let oldIndex = self.posts.findIndex(self.currentPreviewIndex);
      Http.request('POST', '/social/meta').then(function (meta) {
        self.getLinkedPosts(self.activePreviewPost).forEach(function (post){
          post.active = true;
          post.meta = {id: meta.data.id};
          post.link = self.$options.filters.userUrl(post.link);
          requests.push(
            Http.request('POST', '/social/posts', post)
          );
        });

        Promise.all(requests).then(function () {
          self.refreshCurrentView(oldIndex);
        });
      });
    },
    discardPosts() {
      let self = this;
      self.$bvModal.msgBoxConfirm('Möchtest du den Post wirklich verwerfen?', {
          headerBgVariant: 'primary',
          title: 'Posts verwerfen?',
          okTitle: 'Verwerfen',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-danger'
        })
        .then(value => {
          if (value === true) {
            let requests = [];
            Http.request('POST', '/social/meta').then(function (meta) {
              self.getLinkedPosts(self.activePreviewPost).forEach(function (post){
                post.active = false;
                post.meta = {id: meta.data.id};
                post.link = self.$options.filters.userUrl(post.link);
                requests.push(
                  Http.request('POST', '/social/posts', post)
                );
              });

              Promise.all(requests).then(function () {
                self.refreshCurrentView();
                self.$bvModal.hide('post-preview-modal');
              });
            });
          }
      });
    },
    releaseAll(){
      let self = this;
      let index = 0;
      let postsLength = self.posts.length;
      let posts = JSON.parse(JSON.stringify(self.posts));
      posts.forEach(function (postGroup){
        postsLength += (self.getLinkedPosts(postGroup).length - 1);
        if(postGroup.id > 0 && postGroup.active === false){
          self.getLinkedPosts(postGroup).forEach(function (post) {
            post.active = true;
            post.meta = {id: postGroup.meta.id};
            post.link = self.$options.filters.userUrl(post.link);
            Http.request('POST', '/social/posts/' + post.id, post).then(function () {
              index++;
              if (postsLength === index) {
                self.filter.approved = false;
              }
            });
          });
        }
        else {
          Http.request('POST', '/social/meta').then(function (meta) {
            self.getLinkedPosts(postGroup).forEach(function (post) {
              post.meta = {id: meta.data.id};
              Http.request('POST', '/social/posts', post).then(function () {
                index++;
                if (postsLength === index) {
                  self.filter.approved = false;
                }
              });
            });
          });
        }
      });
    },
    changePreview(change){
      let self = this;
      self.postPreview(self.posts[self.posts.findIndex(self.currentPreviewIndex) + change]);
    },
    currentPreviewIndex(post){
      return parseInt(post.id) === parseInt(this.activePreviewPost.id) || parseInt(post.template_id) === parseInt(this.activePreviewPost.template_id)
    },
    deletePost: function (post) {
      let self = this;
      let requests = [];
      self.$bvModal.msgBoxConfirm('Möchtest du alle zugehörigen Posts wirklich löschen?', {
        headerBgVariant: 'primary',
        title: 'Posts löschen?',
        okTitle: 'Ja',
        cancelTitle: 'Nein',
        cancelVariant: 'outline-danger'
      })
      .then(value => {
        if(value === true){
          self.getLinkedPosts(post).forEach(function (post) {
            requests.push(Http.request('DELETE', '/social/posts/' + post.id));
          });

          Promise.all(requests).then(function () {
            self.refreshCurrentView();
            self.$bvModal.hide('post-preview-modal');
          });
        }
      });
    },
    refreshCurrentView(oldIndex = false){
      let self = this;
      self.oldIndex = oldIndex;
      if(typeof(self.$refs.postsTable) !== "undefined"){
        self.$refs.postsTable.refresh();
        // postPreview with oldIndex is handeld in postsRefreshed()
      }
      else {
        self.loadCalendar().then(function (){
          if(oldIndex !== false){
            self.postPreview(self.posts[oldIndex]);
            self.oldIndex = false;
          }
        });
      }
    },
    postsRefreshed(){
      if(this.oldIndex !== false){
        this.postPreview(this.posts[this.oldIndex]);
        this.oldIndex = false;
      }
    }
  }
}

</script>

<style scoped>
.gecco-post .post-header {
  background-color: var(--primary) !important;
}
.gecco-post .post-content {
  border-color: var(--primary) !important;
}
.calendar-day.passed {
  background-color: #EFF4F7;
}
.calendar-day.active .date {
  background-color: var(--primary);
  color: #FFFFFF;
  font-weight: bold;
  border-radius: 100%;
  padding: 2px;
}
.calendar-day:hover {
  background-color: #EFF4F7;
}
.calendar-day .add-post {
  display: none;
  min-width: 18px;
  min-height: 18px;
  font-size: 12px;
}
.calendar-day:hover:not(.passed) .add-post {
  display: inline-block;
}
</style>
